<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Admin</h1>
        <p>
          <router-link to="/terms-and-conditions">
            Terms &amp; Conditions
          </router-link>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AdminIndex',
};
</script>
